<template>
  <section class="app-footer">
    <footer class="footer">
      <div class="container-fluid clearfix">
        <span class="text-muted d-block text-center text-sm-left d-sm-inline-block">
          <i class="mdi mdi-account text-warning"></i>   <span class="text text-secondary">{{ user_name }}</span>
        </span>
        <strong class="text text-white"> | </strong>
        <span class="text-muted d-block text-center text-sm-left d-sm-inline-block">
          <i class="mdi mdi-account-card-details text-warning"></i>   <span class="text text-secondary">{{ role }}</span>
        </span>
        <strong class="text text-white"> | </strong>
        <span v-if=show_inactive_records class="text-muted d-block text-center text-sm-left d-sm-inline-block">
          <i class="mdi mdi-eye text-warning"></i><span class="text text-secondary">Vendo arquivados</span>
        </span>
        <span v-else class="text-muted d-block text-center text-sm-left d-sm-inline-block">
          <i class="mdi mdi mdi-eye-off text-warning"></i><span class="text text-secondary">Arquivo omitido</span>
        </span>
        <strong class="text text-white"> | </strong>
        <span class="text-muted d-block text-center text-sm-left d-sm-inline-block">
          <i class="mdi mdi-code-greater-than text-warning"></i>   <span class="text text-secondary">{{ version }}</span>
        </span>
        <strong class="text text-white"> | </strong>
        <span class="text-muted d-block text-center text-sm-left d-sm-inline-block">
          <div id="enviroment"><strong>{{enviroment}}</strong></div>
        </span>
        <strong class="text text-white"> | </strong>
        <span class="float-none float-sm-right d-block mt-1 mt-sm-0 text-center">
          <strong class="text text-warning">UPIARA</strong>
        </span>
      </div>
    </footer>
  </section>
</template>

<script>

import packageLock from '../../package-lock.json'

export default {
  name: "app-footer",
  data() {
    return {
      user_name: null,
      show_inactive_records: null,
      role: null,
      version: packageLock.version,
      enviroment: process.env.VUE_APP_ENVIROMENT
    }
  },
  mounted() {
    this.version = localStorage.getItem("version") || "";
    const currentVersionAsInt = parseInt(this.version.replace(/\./g, ""));
    const packageLockVersionAsInt = parseInt(
      packageLock.version.replace(/\./g, "")
    );

    if (!this.version || currentVersionAsInt < packageLockVersionAsInt) {
      localStorage.setItem("version", packageLock.version);
      this.version = localStorage.getItem("version");
      localStorage.setItem("hasNewFeatures", true);
      this.$store.commit("setHasNewFeatures", true);
    }

    this.user_name = this.$store.state.user?.name;
    this.show_inactive_records = this.$store.state.user?.show_inactive_records;
    if (this.$store.state.user?.is_system_admin) {
      this.role = 'Administrador do Sistema';
    } else if (this.$store.state.user?.is_account_admin) {
      this.role = 'Administrador da Conta';
    } else {
      this.role = 'Acesso não autorizado';
    }

    var element = document.getElementById("enviroment");
    if (this.enviroment == 'development')
      element.classList.add("text-success");
    else if (this.enviroment == 'staging')
      element.classList.add("text-warning");
    else if (this.enviroment == 'production')
      element.classList.add("text-danger");
  },
};
</script>

<style scoped>
  label {
    font-size: 10px;
  }
</style>