<template>
  <div></div>
</template>

<script>
import { useApi } from './guard';

export default {
  name: 'via-lots-api',

  data() {
    return {
      api: useApi(),
    }
  },

  methods: {
    async getFilterForLotsApi() {
      let filter = {
        id: null,
        lastPage: null,
        perPage: null,
        sortBy: null,
        search: null,
        status: null,
        kind: null,
        kinds: null,
      }
      return filter;
    },

    async getLotApi(id) {
      let filter = this.getFilterForLotsApi();
      filter.id = id;
      let result = await this.getLotsApi(filter);
      if (result != null) return result.lots[0];
    },

    async getLotsApi(filter, exportXLS = false) {
      try {
        var responseType = exportXLS ? 'blob' : null;
        var exportXLSstr = exportXLS ? 'true' : 'false';

        let response = await this.api.get(`/api/v1/lots/crud`, {
          params: {
            id: filter.id,
            page: filter.lastPage,
            itens_per_page: filter.perPage,
            sort_by: filter.sortBy,
            search: filter.search,
            status: filter.status,
            kind: filter.kind,
            kinds: filter.kinds,
            export_xls: exportXLSstr,
          }, responseType: responseType,
        });
        if (exportXLS) {
          var fileURL = window.URL.createObjectURL(new Blob([response.data], { type: 'application/excel' }));
          var fileLink = document.createElement('a');
          fileLink.href = fileURL;
          fileLink.setAttribute('download', 'lots.xls');
          document.body.appendChild(fileLink);
          fileLink.click();
          fileLink.remove();
        } else {
          if (response != null) return response.data;
        }
      } catch (error) {
        this.$notify({ title: 'Erro ao consultar lote(s).', text: error, type: 'error' });
      }
    },

    async getFilesStatus() {
      try {
        let response = await this.api.get('/api/v1/lots/files')
        if (response.data) return response.data
      } catch (error) {
        this.$notify({ title: 'Erro ao coletar informações do lote.', text: error, type: 'error' });
      }
    },

    async saveLotApi(lot, lotId = null) {
      let id;
      try {
        let response = await this.api.post(
          `/api/v1/lots/save`,
          {
            lot: lot,
          },
          {
            params: {
              lot_id: lotId,
            }
          }
        );
        this.$notify({ title: response.data.message, type: response.data.status });
        if (response.data) {
          id = response.data.id;
        }
      } catch (error) {
        this.$notify({ title: 'Erro ao atualizar lote.', text: error, type: 'error' });
      }
      return id;
    },

    async deleteLotApi(lotId, deletedReason) {
      try {
        let response = await this.api.delete(
          `/api/v1/lots/${lotId}`,
          {
            params: {
              excluded_reason: deletedReason
            },
          });
        this.$notify({ title: response.data.message, type: response.data.status });
        return true;
      } catch (error) {
        this.$notify({ title: 'Erro ao deletar.', text: error, type: 'error' });
        return false;
      }
    },

  },

}
</script>
