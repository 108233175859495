var render = function render(){var _vm=this,_c=_vm._self._c;return (this.$can('view', 'lots') && _vm.isOnDashboards)?_c('b-nav-item-dropdown',{staticClass:"preview-list",attrs:{"right":""}},[_c('template',{slot:"button-content"},[_c('div',{class:{
        'nav-link': true,
        'dropdown-toggle': true,
        'count-indicator':
          !_vm.userHasOpenedFilesMenu &&
          _vm.filesStatusChanged &&
          _vm.xlsxFilesData.length,
      },on:{"click":function($event){_vm.userHasOpenedFilesMenu = true}}},[_c('i',{staticClass:"btn icon-bell mx-0 px-3 py-0"}),_c('span',{staticClass:"count"})])]),_c('h6',{staticClass:"p-3 mb-0"},[_vm._v("Notificações")]),(_vm.xlsxFilesData.length)?_c('div',{attrs:{"id":"files-list"}},_vm._l((_vm.xlsxFilesData),function(file){return _c('b-dropdown-item',{key:file.id,staticClass:"preview-item",attrs:{"title":_vm.getFileMessage(file)},on:{"click":function($event){return _vm.handleClickOnFile(file)}}},[(['Processando', 'Gerando'].includes(file.status))?_c('b-spinner',{attrs:{"id":"file-spinner","variant":"warning"}}):_c('div',{staticClass:"preview-thumbnail"},[_c('div',{class:`preview-icon bg-${
            _vm.getFileStatusColor(file.status) || 'success'
          }`},[_c('i',{class:`fa fa-${_vm.getFileStatusIcon(file.status) || 'check'} mx-0`})])]),_c('div',{staticClass:"preview-item-content"},[_c('h6',{staticClass:"preview-subject font-weight-normal"},[_vm._v(" "+_vm._s(file.original_filename)+" ")]),_c('p',{staticClass:"font-weight-light small-text mb-0 text-muted"},[_vm._v(" "+_vm._s(file.status)+" há "+_vm._s(["Processando", "Gerando"].includes(file.status) ? _vm.calculateTime(file.started_at) : _vm.calculateTime(file.finished_at))+" ")])]),(file.status === 'Gerando')?_c('b-button',{staticClass:"text-decoration-none text-danger p-0 ml-auto pl-2",attrs:{"variant":"link","title":"Cancelar geração do arquivo"},on:{"click":function($event){return _vm.cancelFileGeneration(file.id)}}},[_c('i',{staticClass:"mdi mdi-close m-0"})]):_vm._e()],1)}),1):_c('div',[_c('b-dropdown-item',{staticClass:"preview-item",attrs:{"disabled":""}},[_c('div',{staticClass:"preview-item-content"},[_vm._v("Não há nada aqui.")])])],1)],2):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }