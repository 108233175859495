<template>
  <section class="main-view">
    <div class="container-scroller">
      <Header />
      <div class="container-fluid page-body-wrapper">
        <Sidebar />
        <div class="main-panel">
          <div class="content-wrapper">
            <!-- <router-view></router-view> -->
            <router-view :key="$route.fullPath"></router-view>
          </div>
          <!-- content wrapper ends -->
          <Footer />
        </div>
        <!-- main panel ends -->
      </div>
      <!-- page-body-wrapper ends -->
    </div>
  </section>
</template>

<script>
import Header from "./Header";
import Sidebar from "./Sidebar";
import Footer from "./Footer";

export default {
  name: "app-index",

  components: {
    Header,
    Sidebar,
    Footer,
  }, 

  methods: {}
};
</script>
