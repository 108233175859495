<template>
  <b-nav-item-dropdown
    right
    class="preview-list"
    v-if="this.$can('view', 'lots') && isOnDashboards"
  >
    <template slot="button-content">
      <div
        @click="userHasOpenedFilesMenu = true"
        :class="{
          'nav-link': true,
          'dropdown-toggle': true,
          'count-indicator':
            !userHasOpenedFilesMenu &&
            filesStatusChanged &&
            xlsxFilesData.length,
        }"
      >
        <i class="btn icon-bell mx-0 px-3 py-0"></i>
        <span class="count"></span>
      </div>
    </template>
    <h6 class="p-3 mb-0">Notificações</h6>
    <div v-if="xlsxFilesData.length" id="files-list">
      <b-dropdown-item
        class="preview-item"
        @click="handleClickOnFile(file)"
        :title="getFileMessage(file)"
        v-for="file in xlsxFilesData"
        :key="file.id"
      >
        <b-spinner
          id="file-spinner"
          variant="warning"
          v-if="['Processando', 'Gerando'].includes(file.status)"
        ></b-spinner>
        <div class="preview-thumbnail" v-else>
          <div
            :class="`preview-icon bg-${
              getFileStatusColor(file.status) || 'success'
            }`"
          >
            <i
              :class="`fa fa-${getFileStatusIcon(file.status) || 'check'} mx-0`"
            />
          </div>
        </div>
        <div class="preview-item-content">
          <h6 class="preview-subject font-weight-normal">
            {{ file.original_filename }}
          </h6>
          <p class="font-weight-light small-text mb-0 text-muted">
            {{ file.status }} há
            {{
              ["Processando", "Gerando"].includes(file.status)
                ? calculateTime(file.started_at)
                : calculateTime(file.finished_at)
            }}
          </p>
        </div>
        <b-button
          v-if="file.status === 'Gerando'"
          @click="cancelFileGeneration(file.id)"
          variant="link"
          class="text-decoration-none text-danger p-0 ml-auto pl-2"
          title="Cancelar geração do arquivo"
        >
          <i class="mdi mdi-close m-0" />
        </b-button>
      </b-dropdown-item>
    </div>
    <div v-else>
      <b-dropdown-item class="preview-item" disabled>
        <div class="preview-item-content">Não há nada aqui.</div>
      </b-dropdown-item>
    </div>
  </b-nav-item-dropdown>
</template>

<script>
import ViaS3FilesApi from "@/api/ViaS3FilesApi.vue";

export default {
  mixins: [ViaS3FilesApi],

  props: {
    isOnDashboards: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      userHasOpenedFilesMenu: false,
      xlsxFilesData: [],
      filesStatusChanged: false,
      xlsxFilesGetter: null,
    };
  },

  mounted() {
    if (this.isOnDashboards) {
      this.getXlsxFilesData();
      this.xlsxFilesGetter = setInterval(this.getXlsxFilesData, 60000);
    }
  },

  methods: {
    async cancelFileGeneration(file_id) {
      let response = await this.cancelFileGenerationApi(file_id);
      this.$notify({
        title: "Arquivo cancelado!",
        text: response.message,
        type: response.status,
      });
      this.getXlsxFilesData();
    },
    getFileMessage(file) {
      if (file.kind == "Importação") {
        return file.log || "Processando lote";
      } else {
        return !["Gerando", "Erro"].includes(file.status)
          ? "Clique para baixar"
          : file.log || "Gerando arquivo";
      }

    },
    async getXlsxFilesData() {
      this.userHasOpenedFilesMenu = false;
      let xlsxFilesResponse = await this.getXlsxFilesDataApi();

      let oldXlsxFilesData = localStorage.getItem("xlsxFilesData");
      this.xlsxFilesData = xlsxFilesResponse.s3_files;

      this.filesStatusChanged =
        oldXlsxFilesData != JSON.stringify(this.xlsxFilesData);

      if (this.filesStatusChanged)
        localStorage.setItem(
          "xlsxFilesData",
          JSON.stringify(this.xlsxFilesData)
        );
    },
    getFileStatusColor(status) {
      switch (status) {
        case "Concluído":
          return "success";
        case "Processando":
          return "warning";
        case "Erro":
          return "danger";
      }
    },
    getFileStatusIcon(status) {
      switch (status) {
        case "Concluído":
          return "check";
        case "Erro":
          return "exclamation";
      }
    },
    handleClickOnFile(file) {
      if (file.kind === "Importação") {
        let status = file.status;
        if (status === "Concluído") {
          this.$router.replace({
            name: "lots/:id",
            params: { id: file.reference_id },
          });
        } else if (status === "Processando") {
          this.$notify({
            title: "O lote ainda está sendo processado",
            text: "Por favor, aguarde.",
            type: "info",
          });
        } else {
          this.$notify({
            title: "Erro ao processar lote",
            text: file.log,
            type: "error",
          });
        }
      } else {
        if (file.status === "Concluído") {
          fetch(file.public_url)
            .then((response) => response.blob())
            .then((blob) => {
              const blobUrl = window.URL.createObjectURL(blob);
              const a = document.createElement("a");
              a.href = blobUrl;
              a.download = "pedidos.xlsx";
              document.body.appendChild(a);
              a.click();
              window.URL.revokeObjectURL(blobUrl);
              document.body.removeChild(a);
            })
            .catch((error) => console.error("Error downloading file:", error));
        }
      }
    },
    calculateTime(date) {
      let now = new Date();
      let dateToCompare = new Date(`${date}Z`);
      let diffInMs = now - dateToCompare;
      let minutes = Math.floor(diffInMs / 60000);
      let hours = null;

      if (minutes < 1) return "poucos segundos.";

      let text = minutes == 1 ? "minuto" : "minutos";

      if (minutes >= 60) {
        hours = Math.floor(minutes / 60);
        text = hours > 1 ? "horas" : "hora";
      }
      return `${hours ? hours : minutes} ${text}.`;
    },
  },

  beforeDestroy() {
    clearInterval(this.xlsxFilesGetter);
  },
};
</script>

<style scoped>
#file-spinner {
  animation-duration: 1.7s;
}

#files-list {
  max-height: 40vh;
  min-width: fit-content;
  overflow: auto;
}

/*Barra de scroll do Chrome*/
::-webkit-scrollbar {
  width: 4px;
}
::-webkit-scrollbar-track {
  background: transparent;
}
::-webkit-scrollbar-thumb {
  background-color: rgba(155, 155, 155, 0.5);
  border-radius: 20px;
  border: transparent;
}
 
</style>