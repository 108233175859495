export const permissions_abilities = {
    'Ver "Pagos/Pendentes" no dashboard': {
        action: 'see',
        subject: 'paid-pending'
    },
    "Definir origem ao criar pedido": {
        action: 'set',
        subject: 'order-origin'
    },
    "Forçar atribuição de pedidos": {
        action: 'force',
        subject: 'assign-orders'
    },
    'Ver apenas pedidos "Novos" criados no dia atual': {
        action: 'set',
        subject: 'new-status-dev-out'
    },
    "Utilizar serviço da Kangu nos agendamentos de pedido": {
        action: 'use',
        subject: 'kangu'
    },
    "Utilizar 'Pedido do Cliente' nos pedidos da TV": {
        action: 'use',
        subject: 'client-number-on-tv'
    },
    "Pode dar baixa nos pedidos": {
        action: 'set',
        subject: 'order-occurrences'
    },
    "Acesso às telas de SLA": {
        action: 'sidebar',
        subject: 'ui-sla'
    },
    "Pode dar baixas retroativas": {
        action: 'retroactive',
        subject: 'occurrences'
    },
}