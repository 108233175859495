import axios from 'axios';
import User from '../services/user';

let api
const user = new User();

export function createApi() {
  api = axios.create({
    baseURL: `${process.env.VUE_APP_PYTHON_API}`,
    paramsSerializer: params => {
      const result = [];

      for (const key in params) {
        if (Array.isArray(params[key])) {
          for (const value of params[key]) {
            if (value !== null && value !== undefined) result.push(`${encodeURIComponent(key)}=${encodeURIComponent(value)}`);
          }
        } else {
            if (params[key] !== null && params[key] !== undefined) result.push(`${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`);
        }
      }

      return result.join('&');
    }
  });

  api.interceptors.request.use((config) => {
    const token = localStorage.getItem('access_token')
    if (user.isLogged() && !user.isInactive()) {
      config.headers = {
        ...config.headers,
        Authorization: `Bearer ${token}`,
      }
    }
    return config
  })

  api.interceptors.response.use(function (response) {
    return response;
  }, function (error) {
    if (error.response.status == 401) {
      user.loggout();
      window.location.replace("/")
    }
    return error
  });

  return api;
}

export function useApi() {
  if (!api) {
    createApi();
  }
  return api;
}

export default {
  methods: {
    getBearer() {
      const accessToken = localStorage.getItem('access_token')
      return { 'Authorization': `Bearer ${accessToken}` }
    },
    getToken(responseType = null, contentType = null) {
      const auth = { headers: { 
                      ...this.getBearer(),
                    },
                    responseType: responseType, 
                    "Content-Type": contentType
                  }
      return auth
    },
    async formatError(error) {
      if (error.response?.data?.message) {
        return { data: { message: error.response.data.message, status: 'failed' } }
      }
      if (error.toString() === 'Error: Network Error') {
        return { data: { message: 'Não foi possível se conectar ao servidor', status: 'failed' } }
      }
      try {
        if (error.response.config.responseType === 'blob') {
          const textError = await error.response.data.text()
          let errorJson = JSON.parse(textError)
          errorJson = errorJson.message
          const response = { data: { message: errorJson, status: 'error' } }
          return response
        }
        return { data: { message: error.response.data.message, status: 'failed' } }
      } catch {
        return { data: { message: error.toString(), status: 'failed' } }
      }
    },
  }
}