<template>
  <div></div>
</template>

<script>
  import { useApi } from './guard';
  import axios from 'axios';

  export default {
    name: 'via-users-api',

    data() {
      return {
        api: useApi()
      }
    },

    methods: {
      getFilterForUsersApi() {
        let filter = {
          id: null,
          lastPage: null, 
          perPage: null,
          federalId: null,
          accountId: null,
          sortBy: null, 
          search: null, 
          is_transporter: null,
          showInactiveRecords: false,
        }
        return filter;
      },

      async getUserApi(id){
        let filter = this.getFilterForUsersApi();
        filter.id = id;
        filter.showInactiveRecords = true;
        let result = await this.getUsersApi(filter);
        if (result != null) return result.users[0];
      },

      async registerUserClientStoreApi(user) {
        try {
          let response = await axios({
            method: 'post',
            url: `${process.env.VUE_APP_PYTHON_API}/api/v1/users/save_client_store`,
            data: user,
          });
          this.$notify({title: response.data.message, type: response.data.status});
          return response.data;
        } catch(error) {
          this.$notify({title: 'Erro ao criar usuário.', text: error.response.data.detail, type: 'error'});
        }
        
      },

      async getMe(){
        try {
          let response = await this.api.get(`/api/v1/users/me`)
          return response.data
        } catch(error) {
          //ignora token expirado
          if (error.response.status != 401)
            this.$notify({title: 'Erro ao consultar usuário(s).', text: error, type: 'error'});
        }
      },

      async login(email, password) {
        const payloadFormData = new FormData()
        payloadFormData.append('username', email)
        payloadFormData.append('password', password)
        try {
          let response = await this.api.post(`/api/v1/users/user_logged`, payloadFormData)
          if (response?.data?.error){
            this.$notify({title: 'Erro', text: response?.data?.error, type: 'error'});
            return null
          }
          return response.data
        } catch(error) {
          this.$notify({title: error.response.message, text: error, type: 'error'});
        }
      },

      async getUsersApi(filter, exportXLS=false){
        try {
          var responseType = exportXLS ? 'blob' : null;
          var exportXLSstr = exportXLS ? 'true' : 'false';

          let response = await this.api.get(`/api/v1/users/crud`, {
            params: {
              id: filter.id,
              page: filter.lastPage,
              itens_per_page: filter.perPage,
              sort_by: filter.sortBy,
              federal_id: filter.federalId,
              search: filter.search,
              account_id: filter.accountId,
              is_transporter: filter.is_transporter,
              show_inactive_records: filter.showInactiveRecords,
              export_xls: exportXLSstr,
              is_approved: filter.isApproved,
              total_only: filter.totalOnly
            }, responseType: responseType, 
          });
          if (exportXLS) {
            var fileURL = window.URL.createObjectURL(new Blob([response.data], {type: 'application/excel'}));
            var fileLink = document.createElement('a');
            fileLink.href = fileURL;
            fileLink.setAttribute('download', 'users.xls');
            document.body.appendChild(fileLink); 
            fileLink.click();
            fileLink.remove();
          } else {
            if (response != null) return response.data;
          }
        } catch(error) {
          this.$notify({title: 'Erro ao consultar usuário(s).', text: error, type: 'error'});
        }
      },

      async getUsersApiFirebase(id){
        try {
          let response = await axios.get(`https://via-reversa.firebaseio.com/viareversa/geolocalizacao/${id}.json`);
          if (response != null) return response.data;
        } catch(error) {
          this.$notify({title: 'Erro ao consultar usuário no firebase.', text: error, type: 'error'});
        }
      },

      async saveUserApi(user) {
        try {
          let response = await this.api.post(
            '/api/v1/users/save',
            { user: user },
          );
          this.$notify({title: response.data.message, type: response.data.status});
          if (response.data.id) {
            this.userId = response.data.id;
            user = await this.getUserApi(response.data.id);
          }
        } catch(error) {
          this.$notify({title: 'Erro ao criar usuário.', text: error.response.data.detail, type: 'error'});
        }
        return user;
      },

      async updateUserApi(userId, user) {
        try {
          let response = await this.api.put(
            `/api/v1/users/save`,
            { user: user },
            { 
              params: {
                user_id: userId
              },
            },
          );
          this.$notify({title: response.data.message, type: response.data.status});
          if (response.data.id) {
            this.$notify({title: 'Para atualizações de perfil:', text: 'Necessário logoff e login do usuário para surtir efeito.', type: 'warn'});
            this.userId = response.data.id;
            user = await this.getUserApi(response.data.id);
          }
        } catch(error) {
          this.$notify({title: 'Erro ao atualizar usuário.', text: error.response.data.detail, type: 'error'});
        }
        return user;
      },

      setGlobalUser(user) {
        this.$store.state.user = user;
      },
    },

    async created() {
    }
  }
</script>
