import Vue from "vue";
import LayoutIndex from "../layout/Index";
import ViaUsersApi from "../api/ViaUsersApi";
import { ability } from '@/config/ability';

async function userIsApproved(to, from, next) {
  if (localStorage.user_is_approved === 'false') {
    //checo se já não foi aprovado na mesma sessão
    const user = await ViaUsersApi.methods.getUserApi(localStorage.user_id);
    if (user.is_approved === 'false') {
      Vue.notify({title: 'Seu cadastro precisa ser aprovado.', type: 'error'})
      return false
    }
  }
  next()
}

export const
  routes = [
    /* precisa ter o menor nome possível, vai ser enviado url em SMS e limite e 140 caracteres */
    {
      path: '/c',
      name: 'check-order-collect',
      component: () => import('@/components/scheduling/ViaCollectForm.vue'),
      // meta: {
      //   layout: 'full',
      //   redirectIfLoggedIn: true,
      // },
    },
    {
      path: '/checkout',
      name: 'kangu-qr-code',
      component: () => import('@/components/scheduling/KanguQrCode.vue'),
      // meta: {
      //   layout: 'full',
      //   redirectIfLoggedIn: true,
      // },
    },
    {
      path: "/",
      redirect: {
        name: 'login'
      }
    },
    {
      path: "/login",
      name: "login",
      component: () => import("@/pages/auth/Login")
    },
    {
      path: "/register-motoboy",
      name: "register-motoboy",
      component: () => import("@/pages/auth/RegisterMotoboy")
    },
    {
      path: "/auth",
      component: {
        render(c) {
          return c("router-view");
        }
      },
      children: [
        {
          path: "new-password",
          name: "new-password",
          component: () => import("@/pages/auth/NewPassword")
        },
        {
          path: "recovery-password",
          name: "recovery-password",
          component: () => import("@/pages/auth/RecoveryPassword")
        },
        {
          path: "register-client-store",
          name: "register-client-store",
          component: () => import("@/pages/auth/RegisterClientStore")
        },   
      ]
    },
    {
      path: "/cssshare",
      component: {
        render(c) {
          return c("router-view");
        }
      },
      children: [
        {
          path: "orderview",
          name: "orderview",
          component: () => import("@/pages/order/ViaOrderViewOnly")
        },    
        {
          path: "ordersearch",
          name: "ordersearch",
          component: () => import("@/pages/order/ViaOrderSearch")
        },
        {
          path: "box_in/:id",
          name: "box-in-public",
          component: () => import("@/pages/boxes-in/ViaBoxInPublic")
        },
        {
          path: "boxtag/:id",
          name: "boxtag/:id",
          component: () => import("@/pages/boxes-in/ViaPrintBoxTag")
        },
        {
          path: "ordertag/:id",
          name: "ordertag/:id",
          component: () => import("@/pages/order/ViaPrintOrderTag")
        },  
      ]
    },
    {
      path: "/error-pages",
      component: {
        render(c) {
          return c("router-view");
        }
      },
      children: [
        {
          path: "error-404",
          name: "error-404",
          component: () => import("@/pages/error-pages/error-404")
        },
        {
          path: "error-500",
          name: "error-500",
          component: () => import("@/pages/error-pages/error-500")
        }
      ]
    },
    {
      path: "*",
      redirect: "/error-404",
      component: {
        render(c) {
          return c("router-view");
        }
      },
      children: [
        {
          path: "error-404",
          component: () => import("@/pages/error-pages/error-404")
        }
      ]
    },
    {
      path: "/site",
      component: LayoutIndex,
      children: [
        //dashboard
        {
          path: "orders_totals_per_operation",
          name: "orders_totals_per_operation",
          component: () => import("@/pages/dashboard/ViaOrdersTotalsPerOperation")
        },
        {
          path: "orders_per_customer",
          name: "orders_per_customer",
          component: () => import("@/pages/dashboard/ViaOrdersPerCustomer")
        },
        {
          path: "visitors_states_summary",
          name: "visitors_states_summary",
          component: () => import("@/pages/dashboard/ViaVisitorsStatesSummary")
        },
        {
          path: "visitors_support_points_summary",
          name: "visitors_support_points_summary",
          component: () => import("@/pages/dashboard/ViaVisitorsSupportPointsSummary")
        },
        {
          path: "visitors_transporters_summary",
          name: "visitors_transporters_summary",
          component: () => import("@/pages/dashboard/ViaVisitorsTransportersSummary")
        },
        {
          path: "visitors_google_maps",
          name: "visitors_google_maps",
          component: () => import("@/pages/dashboard/ViaVisitorsGoogleMaps")
        },
        {
          path: "dashboard_support_point",
          name: "dashboard_support_point",
          component: () => import("@/pages/dashboard/ViaDashboardSupportPoint")
        },
        {
          path: "dashboard_transporter",
          name: "dashboard_transporter",
          component: () => import("@/pages/dashboard/ViaDashboardTransporter")
        },
        {
          path: "sla_per_cust_by_date",
          name: "sla_per_cust_by_date",
          component: () => import("@/pages/dashboard/ViaSlaPerCustByDate")
        },
        {
          path: "sla_per_stat_by_date",
          name: "sla_per_stat_by_date",
          component: () => import("@/pages/dashboard/ViaSlaPerStatByDate")
        },
        {
          path: "sla_per_supp_by_date",
          name: "sla_per_supp_by_date",
          component: () => import("@/pages/dashboard/ViaSlaPerSuppByDate")
        },
        {
          path: "sla_per_transp_by_date",
          name: "sla_per_transp_by_date",
          component: () => import("@/pages/dashboard/ViaSlaPerTranspByDate")
        },
        {
          path: "sla_per_customer",
          name: "sla_per_customer",
          component: () => import("@/pages/dashboard/ViaSlaPerCustomer")
        },
        {
          path: "sla_per_state",
          name: "sla_per_state",
          component: () => import("@/pages/dashboard/ViaSlaPerState")
        },
        {
          path: "sla_per_support_point",
          name: "sla_per_support_point",
          component: () => import("@/pages/dashboard/ViaSlaPerSupportPoint")
        },
        {
          path: "sla_per_transporter",
          name: "sla_per_transporter",
          component: () => import("@/pages/dashboard/ViaSlaPerTransporter")
        },
        //lot
        {
          path: "lots",
          name: "lots",
          component: () => import("@/pages/lot/ViaLots")
        },
        {
          path: "lots/:id",
          name: "lots/:id",
          component: () => import("@/pages/lot/ViaLot")
        },
        //order
        {
          path: "orders",
          name: "orders",
          component: () => import("@/pages/order/ViaOrders")
        },
        {
          path: "orders/:id",
          name: "orders/:id",
          component: () => import("@/pages/order/ViaOrder")
        },       
        {
          path: "orders/:id/edit",
          name: "orders/:id/edit",
          component: () => import("@/pages/order/ViaOrderForm")
        },
        {
          path: "order/new",
          name: "order/new",
          component: () => import("@/pages/order/ViaOrderNew"),
          beforeEnter: userIsApproved
        },
        {
          path: "order/new_own",
          name: "order/new_own",
          component: () => import("@/pages/order/ViaOrderNewOwn")
        },
        {
          path: "order/:id/checkout",
          name: "order/:id/checkout",
          component: () => import("@/pages/order/ViaOrderCheckout")
        },
        {
          path: "invoices/:kind",
          name: "invoices/:kind",
          component: () => import("@/pages/invoice/ViaInvoices"),
          beforeEnter: (to, from, next) => {
            return ability.can("sidebar", "ui-invoices") ? next() : next(from.name)
          },
        },
        {
          path: "invoice/new/:kind",
          name: "invoice/new/:kind",
          component: () => import("@/pages/invoice/ViaInvoiceNew"),
          beforeEnter: (to, from, next) => {
            return ability.can("sidebar", "ui-invoices") ? next() : next(from.name)
          },
        },
        {
          path: "invoice/:id",
          name: "invoice/:id",
          component: () => import("@/pages/invoice/ViaInvoiceData"),
          beforeEnter: (to, from, next) => {
            return ability.can("sidebar", "ui-invoices") ? next() : next(from.name)
          },
        },
        {
          path: "orders_set_routes",
          name: "orders_set_routes",
          component: () => import("@/pages/order/ViaOrdersSetRoutes")
        },
        {
          path: "order/:id/details",
          name: "order/:id/details",
          component: () => import("@/pages/order/ViaOrderDetails")
        },
        {
          path: "orders_tag",
          name: "orders_tag",
          component: () => import("@/pages/order/ViaOrdersTag"),
        },
        //companies
        {
          path: "companies",
          name: "companies",
          component: () => import("@/pages/company/ViaCompanies")
        },
        {
          path: "companies/:id",
          name: "companies/:id",
          component: () => import("@/pages/company/ViaCompany")
        },
        {
          path: "companies/:id/edit",
          name: "companies/:id/edit",
          component: () => import("@/pages/company/ViaCompanyForm")
        },
        //routes
        {
          path: "routes",
          name: "routes",
          component: () => import("@/pages/scripting/ViaRoutes")
        },
        {
          path: "routes/:id",
          name: "route-preview",
          component: () => import("@/pages/scripting/ViaRoute")
        },
        {
          path: "company/new",
          name: "company/new",
          component: () => import("@/pages/company/ViaCompanyForm")
        },
        //account
        {
          path: "accounts",
          name: "accounts",
          component: () => import("@/pages/account/ViaAccounts")
        },
        {
          path: "accounts/:id",
          name: "accounts/:id",
          component: () => import("@/pages/account/ViaAccount")
        },
        {
          path: "accounts/:id/edit",
          name: "accounts/:id/edit",
          component: () => import("@/pages/account/ViaAccountForm")
        },
        {
          path: "account/new",
          name: "account/new",
          component: () => import("@/pages/account/ViaAccountForm")
        },
        //user
        {
          path: "users",
          name: "users",
          component: () => import("@/pages/user/ViaUsers")
        },
        {
          path: "users/:id",
          name: "users/:id",
          component: () => import("@/pages/user/ViaUser")
        },
        {
          path: "users/:id/edit",
          name: "users/:id/edit",
          component: () => import("@/pages/user/ViaUserForm")
        },
        {
          path: "user/new",
          name: "user/new",
          component: () => import("@/pages/user/ViaUserForm")
        },
        //occurrence
        {
          path: "occurrences",
          name: "occurrences",
          component: () => import("@/pages/occurrence/ViaOccurrences")
        },
        {
          path: "occurrences/:id/edit",
          name: "occurrences/:id/edit",
          component: () => import("@/pages/occurrence/ViaOccurrenceForm")
        },
        {
          path: "occurrence/new",
          name: "occurrence/new",
          component: () => import("@/pages/occurrence/ViaOccurrenceForm")
        },
        //holiday
        {
          path: "holidays",
          name: "holidays",
          component: () => import("@/pages/holiday/ViaHolidays")
        },
        {
          path: "holidays/:id/edit",
          name: "holidays/:id/edit",
          component: () => import("@/pages/holiday/ViaHolidayForm")
        },
        {
          path: "holiday/new",
          name: "holiday/new",
          component: () => import("@/pages/holiday/ViaHolidayForm")
        },
        //calendar
        {
          path: "calendar",
          name: "calendar",
          component: () => import("@/pages/calendar/ViaCalendar")
        },
        //freightPrices
        {
          path: "freightPrices",
          name: "freightPrices",
          component: () => import("@/pages/freight-prices/ViaFreightPrices")
        },
        //module_configurations
        {
          path: "module_configurations_sla",
          name: "module_configurations_sla",
          component: () => import("@/pages/module-configuration/ViaSlaConfiguration")
        },
        {
          path: "module_configurations_process",
          name: "module_configurations_process",
          component: () => import("@/pages/module-configuration/ViaProcessConfiguration")
        },
        {
          path: "module_configurations_sla_audit",
          name: "module_configurations_sla_audit",
          component: () => import("@/pages/module-configuration/ViaSlaConfigurationHistory")
        },
        {
          path: "module_configurations_external",
          name: "module_configurations_external",
          component: () => import("@/pages/module-configuration/ViaExternalConfiguration")
        },
        //box_in
        {
          path: "box_in/:id",
          name: "box_in/:id",
          component: () => import("@/pages/boxes-in/ViaBoxIn")
        },
        {
          path: "boxes_in",
          name: "boxes_in",
          component: () => import("@/pages/boxes-in/ViaBoxesIn")
        },
        {
          path: "box/ticket/:id",
          name: "box-ticket",
          component: () => import("@/pages/boxes-in/ViaBoxTicket")
        },
        //lot_in
        {
          path: "lot_out/:id",
          name: "lot_out/:id",
          component: () => import("@/pages/lots-out/ViaLotOut")
        },
        {
          path: "lots_out",
          name: "lots_out",
          component: () => import("@/pages/lots-out/ViaLotsOut")
        },
        //ocd
        {
          path: "dashboard_store",
          name: "dashboard_store",
          component: () => import("@/pages/ocd/DashboardStore")
        },
        {
          path: "dashboard_store_tv",
          name: "dashboard_store_tv",
          component: () => import("@/pages/ocd/DashboardStoreTv")
        },
        {
          path: "store_check_in",
          name: "store_check_in",
          component: () => import("@/pages/ocd/StoreCheckIn")
        },
        {
          path: "geo-audit",
          name: "geo-audit",
          component: () => import("@/pages/geo-audit/GeoAudit")
        },
      ]
    },
  ]
;
