import Vue from "vue";
import App from "./App.vue";
import VueRouter from "vue-router";
import { routes } from "./router/routes";
import BootstrapVue from "bootstrap-vue";
import VueSweetalert2 from "vue-sweetalert2";
import Notifications from "vue-notification";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { abilitiesPlugin } from "@casl/vue";
import ability from "./config/ability";
import Vuelidate from "vuelidate";
import VueTheMask from "vue-the-mask";
import "./registerServiceWorker";
import money from "v-money";
import Vuex from "vuex";
import VueBarcode from "@chenfengyuan/vue-barcode";
import ViaCep from "vue-viacep";

Vue.component(VueBarcode.name, VueBarcode);
Vue.component("loading-overlay", Loading);

Vue.use(ViaCep);
Vue.use(Vuex);
Vue.use(BootstrapVue);
Vue.use(VueSweetalert2);
Vue.use(Notifications);
Vue.use(VueRouter);
Vue.use(abilitiesPlugin, ability);
Vue.use(Vuelidate);
Vue.use(VueTheMask);
Vue.use(money);

Vue.config.productionTip = false;

const store = new Vuex.Store({
  state() {
    return {
      user: localStorage.getItem("user")
        ? JSON.parse(localStorage.getItem("user"))
        : {},
      ordersTableSorting: localStorage.getItem("ordersTableSorting")
      ? JSON.parse(localStorage.getItem("ordersTableSorting"))
      : null,
      hasNewFeatures: JSON.parse(localStorage.getItem("hasNewFeatures")),
    };
  },
  mutations: {
    setGlobalUser(state, user) {
      state.user = user;
    },
    setOrdersTableSorting(state, ordersTableSortingPermission = null) {
      state.ordersTableSorting = ordersTableSortingPermission;
    },
    setHasNewFeatures(state, value) {
      state.hasNewFeatures = value;
    },
  },
});

const router = new VueRouter({
  linkExactActiveClass: "active",
  scrollBehavior: () => ({ y: 0 }),
  mode: "history",
  base: "../",
  routes,
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
