<template>
  <b-modal id="changelog-modal" size="lg" title="Últimas Mudanças">
    <div id="md-content" v-html="mdContent" class="px-md-4 py-md-3"></div>
  </b-modal>
</template>

<script>
import * as md from "marked";
import Changelog from "../../../changelog.md";

export default {
  name: "changelog-modal",
  data() {
    return {
      mdContent: "",
      heading: "",
    };
  },
  async mounted() {
    this.mdContent = md.marked(Changelog);
  },
};
</script>

<style>
/* MOBILE */
#md-content > h1 {
  font-size: 2.3rem;
}

#md-content > h2 {
  font-size: 1.5rem;
}

#md-content > ul > li {
  font-size: 1rem;
}

/* DESKTOP */
@media (min-width: 768px) {
  #md-content > h1 {
    font-size: 2.5rem;
    text-align: center;
  }

  #md-content > h2 {
    font-size: 1.9rem;
  }

  #md-content > ul > li {
    font-size: 1.2rem;
  }
}
</style>
