<template>
  <section class="app-sidebar">
    <nav class="sidebar sidebar-offcanvas" id="sidebar">
      <br />
      <ul class="nav">
        <li class="nav-item">
          <span class="nav-link" v-b-toggle="'ui-dashboards'" v-if="$can('sidebar', 'ui-dashboard')">
            <i class="ti-bar-chart-alt menu-icon"></i>
            <span class="menu-title">Dashboards</span>
            <i class="menu-arrow"></i>
          </span>
          <b-collapse accordion="sidebar-accordion" id="ui-dashboards" v-if="$can('sidebar', 'ui-dashboard-indicators')">
            <ul class="nav flex-column sub-menu">
              <li class="nav-item">
                <router-link
                  class="nav-link"
                  to="/site/dashboard_support_point/"
                  >Indicadores</router-link
                >
              </li>
              <li class="nav-item" v-if="$can('sidebar', 'ui-dashboard-store')">
                <router-link class="nav-link" to="/site/dashboard_store/"
                  >Loja</router-link
                >
              </li>
              <li class="nav-item" v-if="$can('sidebar', 'ui-dashboard-store')">
                <router-link class="nav-link" to="/site/dashboard_store_tv/"
                  >Loja (TV)</router-link
                >
              </li>
            </ul>
          </b-collapse>
        </li>

        <li v-if="$can('sidebar', 'ui-resumos')" class="nav-item">
          <span class="nav-link" v-b-toggle="'ui-resumos'">
            <i class="ti-clipboard menu-icon"></i>
            <span class="menu-title">Resumos</span>
            <i class="menu-arrow"></i>
          </span>
          <b-collapse accordion="sidebar-accordion" id="ui-resumos">
            <ul class="nav flex-column sub-menu">
              <li class="nav-item">
                <router-link class="nav-link" to="/site/orders_totals_per_operation/"
                  >Totais por Operação</router-link
                >
              </li>
              <li class="nav-item">
                <router-link class="nav-link" to="/site/orders_per_customer/"
                  >Pedidos por Cliente</router-link
                >
              </li>
              <li class="nav-item">
                <router-link
                  class="nav-link"
                  to="/site/visitors_states_summary?order_account=&order_kind="
                  >Pedidos por Estado</router-link
                >
              </li>
              <li class="nav-item">
                <router-link class="nav-link" to="/site/dashboard_transporter/"
                  >Monitoramento</router-link
                >
              </li>
            </ul>
          </b-collapse>
        </li>

        <li v-if="$can('sidebar', 'ui-sla') && user.role != 'USER'" class="nav-item">
          <span class="nav-link" v-b-toggle="'ui-sla'">
            <i class="ti-alarm-clock menu-icon"></i>
            <span class="menu-title">SLA</span>
            <i class="menu-arrow"></i>
          </span>
          <b-collapse accordion="sidebar-accordion" id="ui-sla">
            <ul class="nav flex-column sub-menu">
              <li class="nav-item">
                <router-link class="nav-link" to="/site/sla_per_cust_by_date/"
                  >SLA por Cliente (Data)</router-link
                >
              </li>
              <li class="nav-item">
                <router-link class="nav-link" to="/site/sla_per_stat_by_date/"
                  >SLA por Estado (Data)</router-link
                >
              </li>
              <li class="nav-item">
                <router-link class="nav-link" to="/site/sla_per_supp_by_date/"
                  >SLA por PA (Data)</router-link
                >
              </li>
              <li class="nav-item">
                <router-link class="nav-link" to="/site/sla_per_customer/"
                  >SLA por Cliente</router-link
                >
              </li>
              <li class="nav-item">
                <router-link class="nav-link" to="/site/sla_per_state/"
                  >SLA por Estado</router-link
                >
              </li>
              <li class="nav-item">
                <router-link class="nav-link" to="/site/sla_per_support_point/"
                  >SLA por PA</router-link
                >
              </li>
            </ul>
          </b-collapse>
        </li>

        <li class="nav-item">
          <span class="nav-link" v-b-toggle="'ui-operacao'">
            <i class="mdi mdi-motorbike menu-icon"></i>
            <span class="menu-title">Operação</span>
            <i class="menu-arrow"></i>
          </span>
          <b-collapse accordion="sidebar-accordion" id="ui-operacao">
            <ul class="nav flex-column sub-menu">
              <li v-if="$can('create', 'orders')" class="nav-item">
                <router-link class="nav-link" to="/site/order/new"
                  >Criar Pedido</router-link
                >
              </li>
              <li v-if="$can('view', 'lots')" class="nav-item">
                <router-link class="nav-link" to="/site/lots/"
                  >Lotes</router-link
                >
              </li>
              <li class="nav-item">
                <router-link class="nav-link" to="/site/orders/"
                  >Pedidos</router-link
                >
              </li>
              <li v-if="$can('printTag', 'orders')" class="nav-item">
                <router-link class="nav-link" to="/site/orders_tag/"
                  >Expedição</router-link
                >
              </li>
              <li v-if="$can('setRoutes', 'orders')" class="nav-item">
                <router-link class="nav-link" to="/site/orders_set_routes/"
                  >Roteirização</router-link
                >
              </li>
              <li v-if="$can('setRoutes', 'orders')" class="nav-item">
                <router-link class="nav-link" to="/site/routes/"
                  >Rotas</router-link
                >
              </li>
              <li v-if="$can('view', 'store-check-in')" class="nav-item">
                <router-link class="nav-link" to="/site/store_check_in"
                  >Check In</router-link
                >
              </li>
              <!--
                <li v-if="$can('view','orders')" class="nav-item">
                  <router-link class="nav-link" to="/site/order/new">Criar Pedido</router-link>
                </li>
              -->
            </ul>
          </b-collapse>
        </li>

        <li class="nav-item" v-if="$can('sidebar', 'ui-invoices')">
          <span class="nav-link" v-b-toggle="'ui-faturas'">
            <i class="mdi mdi-cash-usd menu-icon"></i>
            <span class="menu-title">Faturas</span>
            <i class="menu-arrow"></i>
          </span>
          <b-collapse accordion="sidebar-accordion" id="ui-faturas">
            <ul class="nav flex-column sub-menu">
              <li class="nav-item">
                <router-link class="nav-link" to="/site/invoices/to-receive"
                  >A receber</router-link
                >
              </li>
              <li class="nav-item">
                <router-link class="nav-link" to="/site/invoices/to-pay"
                  >A pagar</router-link
                >
              </li>
            </ul>
          </b-collapse>
        </li>

        <li v-if="$can('sidebar', 'ui-estoque')" class="nav-item">
          <span class="nav-link" v-b-toggle="'ui-estoque'">
            <i class="fa fa-truck menu-icon"></i>
            <span class="menu-title">Devoluções</span>
            <i class="menu-arrow"></i>
          </span>
          <b-collapse accordion="sidebar-accordion" id="ui-estoque">
            <ul class="nav flex-column sub-menu">
              <li class="nav-item">
                <router-link class="nav-link" to="/site/boxes_in/"
                  >DEV IN</router-link
                >
              </li>
              <li v-if="$can('sidebar', 'ui-estoque-dev-out')" class="nav-item">
                <router-link class="nav-link" to="/site/lots_out/"
                  >DEV OUT</router-link
                >
              </li>
            </ul>
          </b-collapse>
        </li>

        <li class="nav-item" v-if="$can('sidebar', 'ui-cadastros')">
          <span class="nav-link" v-b-toggle="'ui-cadastros'">
            <i class="ti-layout menu-icon"></i>
            <span class="menu-title">Cadastros</span>
            <i class="menu-arrow"></i>
          </span>
          <b-collapse accordion="sidebar-accordion" id="ui-cadastros">
            <ul class="nav flex-column sub-menu">
              <li v-if="$can('sidebar', 'ui-cadastros-companies')" class="nav-item">
                <router-link class="nav-link" to="/site/companies/"
                  >Empresas</router-link
                >
              </li>
              <li v-else-if="$can('see', 'own-company')" class="nav-item">
                <router-link class="nav-link" :to="`/site/companies/${user.company_id}`"
                  >Empresa</router-link
                >
              </li>
              <li v-if="$can('sidebar', 'ui-cadastros-accounts')" class="nav-item">
                <router-link class="nav-link" to="/site/accounts/"
                  >Contas</router-link
                >
              </li>
              <li v-else-if="$can('see', 'own-account')" class="nav-item">
                <router-link class="nav-link" :to="`/site/accounts/${user.account_id}`"
                  >Conta</router-link
                >
              </li>
              <li class="nav-item" v-if="$can('sidebar', 'ui-cadastros-users')">
                <router-link class="nav-link" to="/site/users/"
                  >Usuários</router-link
                >
              </li>
              <li v-if="$can('sidebar', 'ui-cadastros-occurrences')" class="nav-item">
                <router-link class="nav-link" to="/site/occurrences/"
                  >Ocorrências</router-link
                >
              </li>
              <li v-if="$can('sidebar', 'ui-cadastros-shipping')" class="nav-item">
                <router-link class="nav-link" to="/site/freightPrices/"
                  >Fretes</router-link
                >
              </li>
              <li v-if="$can('sidebar', 'ui-cadastros-holidays')" class="nav-item">
                <router-link class="nav-link" to="/site/holidays/"
                  >Feriados</router-link
                >
              </li>
            </ul>
          </b-collapse>
        </li>

        <li class="nav-item" v-if="$can('see', 'calendar')">
          <router-link class="nav-link" to="/site/calendar">
            <i class="ti-calendar menu-icon"></i>
            <span class="menu-title">Calendário</span>
          </router-link>
        </li>

        <li v-if="$can('sidebar', 'ui-relatorios')" class="nav-item">
          <a
            class="nav-link"
            href="https://viareversa-bi.csssolutions.com.br"
            target="_blank"
            rel="noopener"
          >
            <i class="ti ti-dashboard menu-icon"></i>
            <span class="menu-title"
              >BI - Metabase <i class="mdi mdi-open-in-new"></i
            ></span>
          </a>
        </li>

        <li v-if="$can('sidebar', 'ui-configuracoes')" class="nav-item">
          <span class="nav-link" v-b-toggle="'ui-configuracoes'">
            <i class="fa fa-gears menu-icon"></i>
            <span class="menu-title">Configurações</span>
            <i class="menu-arrow"></i>
          </span>
          <b-collapse accordion="sidebar-accordion" id="ui-configuracoes">
            <ul class="nav flex-column sub-menu">
              <li class="nav-item">
                <router-link
                  class="nav-link"
                  to="/site/module_configurations_sla/"
                  >SLA
                  <div class="ml-1 mdi mdi-alarm-multiple"></div>
                </router-link>
              </li>
              <li class="nav-item">
                <router-link
                  class="nav-link"
                  to="/site/module_configurations_process/"
                  >Processamento
                  <div class="ml-1 fa fa-gear"></div>
                </router-link>
              </li>
              <li class="nav-item">
                <router-link
                  class="nav-link"
                  to="/site/module_configurations_external/"
                  >Externos</router-link
                >
              </li>
            </ul>
          </b-collapse>
        </li>

        <li class="nav-item" v-if="ocd != 'true'">
          <a class="nav-link" @click="docsUrl">
            <i class="ti-book menu-icon"></i>
            <span class="menu-title"
              >Documentação <i class="mdi mdi-open-in-new"></i
            ></span>
          </a>
        </li>

        <li class="nav-item" v-if="ocd != 'true'">
          <a class="nav-link" @click="orderByFederalId">
            <i class="fa fa-globe menu-icon"></i>
            <span class="menu-title"
              >Consulta Pública <i class="mdi mdi-open-in-new"></i
            ></span>
          </a>
        </li>

        <li v-if="$can('sidebar', 'ui-apis')" class="nav-item">
          <a class="nav-link" @click="apiUrlSwagger">
            <i class="mdi mdi-json menu-icon"></i>
            <span class="menu-title"
              >APIs <i class="mdi mdi-open-in-new"></i
            ></span>
          </a>
        </li>

        <li v-if="$can('view', 'geo-audit')" class="nav-item">
          <router-link class="nav-link" to="/site/geo-audit/">
            <i class="fa fa-map menu-icon"></i>
            <span class="menu-title">Audit. de Localização</span>
          </router-link>
        </li>

        <li
          class="nav-item"
          v-b-modal.changelog-modal
          @click="handleNewFeaturesBadge"
          v-if="ocd != 'true'"
        >
          <div class="nav-link">
            <i class="ti ti-medall menu-icon"></i>
            <span class="menu-title">Novidades</span>
            <i
              v-if="$store.state.hasNewFeatures"
              class="fa fa-circle"
              id="news-badge"
            />
          </div>
        </li>
      </ul>
    </nav>
    <Changelog />
  </section>
</template>

<script>
import Changelog from "../components/news/Changelog.vue";

export default {
  name: "app-sidebar",
  components: { Changelog },
  data: function () {
    return {
      user: {},
      collapses: [{ show: false }, { show: false }, { show: false }],
      enviroment: process.env.VUE_APP_ENVIROMENT,
      ocd: process.env.VUE_APP_OCD_MODE,
    };
  },
  methods: {
    handleNewFeaturesBadge() {
      localStorage.removeItem("hasNewFeatures");
      this.$store.state.hasNewFeatures = false;
    },
    collapseAll() {
      var exp_elm = document.getElementsByClassName("show");
      if (exp_elm.length > 0) {
        var elm_id = exp_elm[0].id;
        this.$root.$emit("bv::toggle::collapse", elm_id);
      }
    },

    orderByFederalId() {
      let routeData = this.$router.resolve({ name: "ordersearch" });
      window.open(routeData.href, "_blank");
    },

    docsUrl() {
      window.open(`${process.env.VUE_APP_MKDOCS_URL}`, "_blank");
    },

    apiUrlLegacySwagger() {
      window.open(`${process.env.VUE_APP_PYTHON_API}`, "_blank");
    },

    apiUrlSwagger() {
      window.open(`${process.env.VUE_APP_PYTHON_API}/docs`, "_blank");
    },
  },

  mounted() {
    const body = document.querySelector("body");
    // add class 'hover-open' to sidebar navitem while hover in sidebar-icon-only menu
    document.querySelectorAll(".sidebar .nav-item").forEach(function (el) {
      el.addEventListener("mouseover", function () {
        if (body.classList.contains("sidebar-icon-only")) {
          el.classList.add("hover-open");
        }
      });
      el.addEventListener("mouseout", function () {
        if (body.classList.contains("sidebar-icon-only")) {
          el.classList.remove("hover-open");
        }
      });
    });
    this.user = this.$store.state.user
  },
  watch: {
    $route() {
      document.querySelector("#sidebar").classList.toggle("active");
    },
  },
};
</script>

<style scoped>
#news-badge {
  color: #f44;
  margin-left: auto;
}
</style>
