<template>
  <div></div>
</template>

<script>
import { useApi } from "./guard";

export default {
  name: "via-s3-files-api",

  data() {
    return {
      api: useApi(),
    };
  },

  methods: {
    async getXlsxFilesDataApi() {
      try {
        let response = await this.api.get(`/api/v1/s3_files/export_info`);
        if (response.data) return response.data;
      } catch (error) {
        this.$notify({
          title: "Erro ao buscar dados de exportação .xlsx.",
          text: error,
          type: "error",
        });
      }
    },

    async cancelFileGenerationApi(file_id) {
      try {
        let response = await this.api.patch(`/api/v1/s3_files/cancel_xlsx_generation/${file_id}`);
        if (response.data) return response.data;
      } catch (error) {
        this.$notify({
          title: "Erro ao cancelar geração de arquivo .xlsx.",
          text: error,
          type: "error",
        });
      }
    },
  },
};
</script>
