const asyncLocalStorage = {
    setItem: function (key, value) {
        return Promise.resolve().then(function () {
            localStorage.setItem(key, value);
        });
    },
    getItem: function (key) {
        return Promise.resolve().then(function () {
            return localStorage.getItem(key);
        });
    }
}
export default class User {
    setAccessToken(accessToken) {
        asyncLocalStorage.setItem('access_token', accessToken);
    }
    login(user, permissions){
        asyncLocalStorage.setItem('user_id', user.id);
        asyncLocalStorage.setItem('user_is_system_admin', user.is_system_admin == 1);
        asyncLocalStorage.setItem('user_is_account_admin', user.is_account_admin == 1);
        asyncLocalStorage.setItem('user_is_one_click_client', user.is_one_click_client == 1);
        asyncLocalStorage.setItem('ocd_mode', process.env.VUE_APP_OCD_MODE == 'true');
        asyncLocalStorage.setItem('user',JSON.stringify(user ? user : {}));
        asyncLocalStorage.setItem('permissions',JSON.stringify(permissions ? permissions : {}));
        asyncLocalStorage.setItem('user_role', user.role);
        //JSON.parse(localStorage.getItem('user')); //leitura
    }

    loggout() {
        asyncLocalStorage.setItem('user_id', null);
        asyncLocalStorage.setItem('user_is_system_admin', null);
        asyncLocalStorage.setItem('user_is_account_admin', null);
        asyncLocalStorage.setItem('user_is_account_admin', null);
        asyncLocalStorage.setItem('user_is_one_click_client', null);
        asyncLocalStorage.setItem('ocd_mode', null);
        asyncLocalStorage.setItem('access_token', null);
        asyncLocalStorage.setItem('user', null);
        asyncLocalStorage.setItem('permissions', null);
        asyncLocalStorage.setItem('user_role', null);
        asyncLocalStorage.setItem('xlsxFilesData', []);
    }

    currentUserId() {
        return (this.isLogged() ? asyncLocalStorage.getItem('user_id') : null);
    }

    isLogged() {
        var token = localStorage.getItem('access_token');
        return ((typeof(token) !== "undefined") && token !== "undefined" && token !== null && token !== "null");
    }

    isInactive() {
        let user = localStorage.getItem('user', null);
        return user?.is_inactive;
    }
}