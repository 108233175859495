<template>
  <div id="app">
    <notifications position="top center" width="60%"/>
    <router-view></router-view>
    <via-users-api ref="ViaUsersApi"/>
  </div>
</template>

<script>

import User from './services/user';
import ViaUsersApi from './api/ViaUsersApi';

export default {
  name: 'app',

  components: {
    'via-users-api': ViaUsersApi,
  },

  async mounted() {
    this.userService = new User();
    if ((window.location.href.includes("/site"))) {
      if (!this.userService.isLogged()) 
        this.$router.replace({ name: "login" });
      else if (!(this.$store.state.user)) {
        let currentUserId = await this.userService.currentUserId()
        this.$store.state.user = await this.$refs.ViaUsersApi.getUserApi(currentUserId);
        if (!(this.$store.state.user))
          this.$router.replace({ name: "login" });
      }
    }
  },
}
</script>

<style lang="scss">
@import "../node_modules/@mdi/font/css/materialdesignicons.min.css";
@import "../node_modules/flag-icon-css/css/flag-icon.min.css";
@import "../node_modules/font-awesome/css/font-awesome.min.css";
@import "../node_modules/simple-line-icons/css/simple-line-icons.css";
@import "../node_modules/ti-icons/css/themify-icons.css";
@import "../node_modules/sweetalert2/dist/sweetalert2.min.css";
@import "../node_modules/vue-snotify/styles/material.css";
@import "../node_modules/codemirror/lib/codemirror.css";
@import "../node_modules/c3/c3.min.css";
@import "../node_modules/chartist/dist/chartist.min.css";
@import "./assets/scss/style";
</style>
