<template>
  <div></div>
</template>

<script>
import axios from "axios";
import guard from "./guard";

export default {
  name: "via-companies-api",

  mixins: [guard],

  data() {
    return {};
  },

  methods: {
    getFilterForCompaniesApi() {
      let filter = {
        id: null,
        lastPage: null,
        perPage: null,
        sortBy: null,
        kind: null,
        search: null,
        url_prefix: null,
        showInactiveRecords: null,
      };
      return filter;
    },

    async getCompanyApi(id) {
      let filter = this.getFilterForCompaniesApi();
      filter.id = id;
      filter.showInactiveRecords = true;
      let result = await this.getCompaniesApi(filter);
      if (result != null) return result.companies[0];
    },

    async getCompanyLogoApi(subdomain) {
      let result = await axios.get(
        `${process.env.VUE_APP_PYTHON_API}/api/v1/companies/logo`,
        {
          params: {
            url_prefix: subdomain,
          },
        }
      );
      return result.data;
    },

    async getCompaniesApi(filter, exportXLS = false) {
      try {
        var responseType = exportXLS ? "blob" : null;
        var exportXLSstr = exportXLS ? "true" : "false";

        let response = await axios.get(
          `${process.env.VUE_APP_PYTHON_API}/api/v1/companies/crud`,
          {
            params: {
              id: filter.id,
              page: filter.lastPage,
              itens_per_page: filter.perPage,
              sort_by: filter.sortBy,
              kind: filter.kind,
              search: filter.search,
              url_prefix: filter.url_prefix,
              show_inactive_records: filter.showInactiveRecords,
              export_xls: exportXLSstr,
            },
            responseType: responseType,
            ...this.getToken(),
          }
        );
        if (exportXLS) {
          var fileURL = window.URL.createObjectURL(
            new Blob([response.data], { type: "application/excel" })
          );
          var fileLink = document.createElement("a");
          fileLink.href = fileURL;
          fileLink.setAttribute("download", "companies.xls");
          document.body.appendChild(fileLink);
          fileLink.click();
          fileLink.remove();
        } else {
          if (response != null) return response.data;
        }
      } catch (error) {
        this.$notify({
          title: "Erro ao consultar empresa(s).",
          text: error,
          type: "error",
        });
      }
    },

    async getClientCompanies(search) {
      try {
        let response = await axios({
          method: "get",
          url: `${process.env.VUE_APP_PYTHON_API}/api/v1/companies/clients`,
          headers: this.getBearer(),
          params: {
            search: search,
          },
        });
        return response.data;
      } catch (error) {
        this.$notify({
          title: "Erro ao buscar empresas clientes.",
          text: error.response.data.detail,
          type: "error",
        });
      }
    },

    async saveCompanyApi(company) {
      let id;
      try {
        let response = await axios({
          method: "post",
          url: `${process.env.VUE_APP_PYTHON_API}/api/v1/companies`,
          headers: this.getBearer(),
          data: {
            company: company,
          },
        });
        this.$notify({
          title: response.data.message,
          type: response.data.status,
        });
        if (response.data.status != "error") {
          id = response.data.id;
        }
      } catch (error) {
        this.$notify({
          title: "Erro ao adicionar empresa.",
          text: error.response.data.detail,
          type: "error",
        });
      }
      return id;
    },

    async updateCompanyApi(company, companyId) {
      let id;
      try {
        let response = await axios({
          method: "patch",
          url: `${process.env.VUE_APP_PYTHON_API}/api/v1/companies`,
          headers: this.getBearer(),
          params: {
            company_id: companyId,
          },
          data: {
            company: company,
          },
        });
        this.$notify({
          title: response.data.message,
          type: response.data.status,
        });
        if (response.data.status != "error") {
          id = response.data.id;
        }
      } catch (error) {
        this.$notify({
          title: "Erro ao atualizar empresa.",
          text: error.response.data.detail,
          type: "error",
        });
      }
      return id;
    },

    dictL(value) {
      if (typeof value === "boolean") return value ? "Sim" : "Não";
      else if (Array.isArray(value)) {
        return value.reduce((stringRet, text) => text + " " + stringRet, "");
      } else return value;
    },

    async updateCompanyLogoApi(file, companyId) {
      const formData = new FormData();
      formData.append("file", file);
      try {
        let response = await axios({
          method: "put",
          url: `${process.env.VUE_APP_PYTHON_API}/api/v1/companies/upload-logo/${companyId}`,
          headers: { ...this.getBearer() },
          data: formData,
        });
        this.$notify({
          title: response.data.message,
          type: response.data.status,
        });
        if (response.data.status != "error") {
          this.$notify({
            title: response.data.message,
            type: response.data.status,
          });
        }
      } catch (error) {
        this.$notify({
          title: "Erro ao atualizar logo.",
          text: error.response.data.detail,
          type: "error",
        });
      }
    },
  },

  async created() {},
};
</script>
